import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScroll, faUser, faGift, faFileLines, faAddressCard, faCreditCard, faCreditCardAlt, faGifts, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import Logout from '../../assets/images/logout.png';
import Path12 from '../../assets/images/Path12.png';
import Path12Active from '../../assets/images/Path12_activeBlue.png';
import Path10 from '../../assets/images/Path10.png';
import Path10Active from '../../assets/images/Path10_activeBlue.png';
import { logout } from '../../store/actions/Auth/authActions';
import { resetCreateCampaign } from '../../store/actions/Campaign/createCampaignActions';
import Helpers from '../../utils/Helpers';
import "../../assets/css/style.css";
import coupon from '../../assets/images/coupon.png';
import couponcolor from '../../assets/images/couponcolor.png';

const styles = {
    sidebarItems: {
        cursor: 'pointer',
        width: '25px !important',
        height: '25px !important',
    },
};


const Sidebar = ({ classes }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userType = useSelector((state) => state.authReducer.userDetails.type_id);

    const handleLogout = () => {
        let defaultState = Helpers.returnDefaultState();
        dispatch(logout({}));
        dispatch(resetCreateCampaign(defaultState.initialBillingState, defaultState.initialCampaignState, defaultState.initialProgressState, defaultState.previousCards));
        if (userType == 1805) {
            navigate('/agency-login');
        } else {
            navigate('/');
        }
    }
    return (
        <div className="sidebar">
            <div className="baritems flex-column d-flex">
                <div className={location.pathname === '/dashboard' ? "first-item" : "seconditem"}>
                    <Link to="/dashboard">
                        <img className={classes.sidebarItems} id="dashboard-icon" src={location.pathname === '/dashboard' ? Path12Active : Path12} width="25px" alt="" />
                    </Link>
                </div>
                {/* {userType !== 1801 &&  
                    <div className={location.pathname === '/new-business'  ? "first-item" : "seconditem"}>
                        <Link to="/new-business">
                            <FontAwesomeIcon icon={faBriefcase} className={classes.sidebarItems} color={location.pathname === '/new-business' ? '#1549d6' : '#FFFFFF'} id="new-business-icon" alt=""  />
                        </Link>
                    </div>
                } */}
                {userType !== 1805 &&
                    <div className={location.pathname === '/users' ? "first-item" : "seconditem"}>
                        <Link to="/users">
                            <FontAwesomeIcon icon={faUser} className={classes.sidebarItems} color={location.pathname === '/users' ? '#1549d6' : '#FFFFFF'} id="users-icon" alt="" />
                        </Link>
                    </div>
                }
                {userType !== 1805 &&
                    <div className={location.pathname === '/post-history' ? "first-item" : "seconditem"}>
                        <Link to="/post-history">
                            <FontAwesomeIcon icon={faScroll} className={classes.sidebarItems} color={location.pathname === '/post-history' ? '#1549d6' : '#FFFFFF'} id="scroll-icon" alt="" />
                        </Link>
                    </div>
                }
                {/* <div className="seconditem">
                    <img className={classes.sidebarItems} src={Archive} width="25px" alt="" />
                </div>
                <div className="seconditem">
                    <img className={classes.sidebarItems} src={BXSReports} width="25px" alt="" />
                </div> */}
                {userType !== 1805 &&
                    <div className={location.pathname === '/campaigns' ? "first-item" : "seconditem"}>
                        <Link to="/campaigns">
                            <img className={classes.sidebarItems} id="campaign-icon" src={location.pathname === '/campaigns' ? Path10Active : Path10} width="25px" alt="" />
                        </Link>
                    </div>
                }
                {(userType === 1802 || userType === 1806) &&
                    <div className={location.pathname === '/coupon' ? "first-item" : "seconditem"}>
                        <Link to="/coupon">
                            <FontAwesomeIcon icon={faGift} className={classes.sidebarItems} color={location.pathname === '/coupon' ? '#1549d6' : '#FFFFFF'} id="scroll-icon" alt="" />
                        </Link>
                    </div>
                }

                {(userType === 1802 || userType === 1806) &&
                    <div className={location.pathname === '/globalcoupon' ? "first-item" : "seconditem"}>
                        <Link to="/globalcoupon">
                            <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 28, height: 28,marginLeft:-1.7 }} viewBox="0 0 24 24" fill={location.pathname === '/globalcoupon' ? '#1549d6' : '#FFFFFF'} >
                                <path d="M9.375 3a1.875 1.875 0 0 0 0 3.75h1.875v4.5H3.375A1.875 1.875 0 0 1 1.5 9.375v-.75c0-1.036.84-1.875 1.875-1.875h3.193A3.375 3.375 0 0 1 12 2.753a3.375 3.375 0 0 1 5.432 3.997h3.943c1.035 0 1.875.84 1.875 1.875v.75c0 1.036-.84 1.875-1.875 1.875H12.75v-4.5h1.875a1.875 1.875 0 1 0-1.875-1.875V6.75h-1.5V4.875C11.25 3.839 10.41 3 9.375 3ZM11.25 12.75H3v6.75a2.25 2.25 0 0 0 2.25 2.25h6v-9ZM12.75 12.75v9h6.75a2.25 2.25 0 0 0 2.25-2.25v-6.75h-9Z" />
                            </svg>
                        </Link>
                    </div>
                }




                {(userType === 1802 || userType === 1801 || userType === 1806) && (
                    <div className={location.pathname === '/brand-summary' ? "first-item" : "seconditem"}>
                        <Link to="/brand-summary">
                            <FontAwesomeIcon icon={faFileLines} className={classes.sidebarItems} color={location.pathname === '/brand-summary' ? '#1549d6' : '#FFFFFF'} id="scroll-icon" alt="" />
                        </Link>
                    </div>
                )}

                {(userType === 1802 || userType === 1806) && (
                    <div className={location.pathname === '/brands' ? "first-item" : "seconditem"}>
                        <Link to="/brands">
                            <FontAwesomeIcon icon={faAddressCard} className={classes.sidebarItems} color={location.pathname === '/brands' ? '#1549d6' : '#FFFFFF'} id="scroll-icon" alt="" />
                        </Link>
                    </div>
                )}

                {(userType === 1802 || userType === 1806) && (
                    <div className={location.pathname === '/Payments' ? "first-item" : "seconditem"}>
                        <Link to="/Payments">
                            <FontAwesomeIcon icon={faCreditCardAlt} className={classes.sidebarItems} color={location.pathname === '/Payments' ? '#1549d6' : '#FFFFFF'} id="scroll-icon" alt="" />
                        </Link>
                    </div>
                )}

                <div className="seconditem" onClick={handleLogout}>
                    <img className={classes.sidebarItems} id="logout-icon" src={Logout} width="25px" alt="" />
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(Sidebar);