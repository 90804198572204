import axios from 'axios';

/**
 * @description POST API to create Global coupon
 * @async
 * @function PostCreateGlobalCouponAPI
 * @returns Promise
**/


const PostCreateGlobalCouponAPI = async (CouponAmount, OneTimeUse, PerUseAmount) => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/coupon/create/global/coupon`;
  const config = {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }
  const formBody = {
    "coupon_amount": CouponAmount,
    "one_time_use": OneTimeUse,
    "per_use_amount": PerUseAmount
  }

  try {
    const response = await axios.post(apiUrl, formBody, config)
    return response.data

  } catch (e) {
    return e
  }
}

export default PostCreateGlobalCouponAPI;
