import React, { useEffect, useState } from "react"
import { useFormik } from 'formik'
import { withStyles } from "@material-ui/core"
import { WHITE_BG, GRAY_BG_F7, PRIMARY_COLOR } from "../../utils/CssConstants"
import * as yup from 'yup'
import CircularProgress from '@material-ui/core/CircularProgress'
import PostCreateCouponAPI from '../../services/Coupon/PostCreateCouponAPI'
import { toast, ToastContainer } from "react-toastify"
import PostCreateGlobalCouponAPI from "../../services/GlobalCoupon/PostCreateGlobalCouponAPI"
import SwitchButton from "../Custom/SwitchButton"


const styles = theme => ({
    rootNewBusiness: {
        display: 'flex',
        background: WHITE_BG,
        margin: '30px 10px',
        padding: 15,
        borderRadius: 20,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
    },
    componentCont: {
        background: GRAY_BG_F7,
        width: '100%',
        borderRadius: 30,
        minHeight: '100vh',
        height: 'auto'
    },
    campaignListTop: {
        margin: '30px 30px 30px 50px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    campaignListHeading: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 24,
        marginBottom: 20,
        width: '100%'
    },
    campaignListContent: {
        width: '50%',
        margin: '0 30px 30px 50px',
        [theme.breakpoints.down('sm')]: {
            width: '75%'
        }
    },
    formGroup: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0',
    },
    formLabels: {
        fontWeight: 'bold',
        fontSize: 18,
    },
    formInputs: {
        width: '100%',
        padding: 10,
        fontWeight: 'normal',
        fontSize: 16,
        borderRadius: 5,
        border: '0.5px solid #CECECE'
    },
    labelError: {
        color: 'rgba(255, 0, 0, 0.9)',
        fontSize: 18,
    },
    submitBtn: {
        background: PRIMARY_COLOR,
        color: WHITE_BG,
        border: 'none',
        borderRadius: 5,
        height: 60,
        width: '25%',
        marginBottom: 63,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            width: '50%'
        }
    },
    btnLoading: {
        color: '#FFF !important'
    },
    couponSuccess: {
        background: WHITE_BG,
        padding: 20,
        borderRadius: 5,
        border: '1px solid #1549d6'
    },
    switch: {
        position: 'relative',
        display: 'inline-block',
        marginLeft: 15,
        width: 32,
        height: 20,
        '& input': {
            opacity: 0,
            width: 0,
            height: 0
        }
    },
    slider: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#CCC',
        '-webkit-transition': '.4s',
        transition: '.4s',
        borderRadius: 34,
        "&::before": {
            position: 'absolute',
            content: "''",
            height: 16,
            width: 16,
            left: 1,
            bottom: 2,
            backgroundColor: WHITE_BG,
            '-webkit-transition': '.4s',
            transition: '.4s',
            borderRadius: '50%'
        }
    },
    checkbox: {
        '&:checked + $slider': {
            backgroundColor: PRIMARY_COLOR
        },
        '&:focus + $slider': {
            boxShadow: '0 0 1px ' + PRIMARY_COLOR
        },
        '&:checked + $slider::before': {
            '-webkit-transform': 'translateX(13px)',
            '-ms-transform': 'translateX(13px)',
            transform: 'translateX(13px)'
        }
    },
    round: {
        borderRadius: 34,
    },
    '$slide::before $round::before': {
        borderRadius: '50%',
    }
})

const GlobalCoupon = ({ classes }) => {

    const [loading, setLoading] = useState(false)
    const [apiSuccess, setApiSuccess] = useState({})

    const formik = useFormik({
        initialValues: {
            amount: '',
            isonetimeuse: true,
            peruseamount: ''
        },
        validationSchema: yup.object({
            amount: yup.number().required('Coupon amount is required'),
            isonetimeuse: yup.boolean().required('Is one time use is required'),
            peruseamount: yup.number().required('Per use amount is required')

        }),
        onSubmit: values => handleSubmit(values)
    })

    const handleSubmit = async inputs => {
        setApiSuccess({})
        setLoading(true)
        await PostCreateGlobalCouponAPI(inputs.amount, inputs.isonetimeuse, inputs.peruseamount).then(res => {
            setLoading(false)
            if (res.status === 'SUCCESS') {
                toast.success(res.message)
                setApiSuccess(res)
            } else {
                toast.error(res.response.data.error)
            }
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        document.title = "Create Global Coupon - Thumzup";
    }, [])

    return (
        <>
            <div className={classes.rootNewBusiness}>
                <div className={classes.componentCont}>
                    <div className={classes.campaignListTop}>
                        <h3 className={classes.campaignListHeading} id="page-heading">Create a Global Coupon</h3>
                    </div>

                    <div className={classes.campaignListContent}>
                        <form onSubmit={formik.handleSubmit}>
                            <div className={classes.formGroup}>
                                <label className={`${classes.formLabels} ${formik.touched.amount && formik.errors.amount && classes.labelError}`} htmlFor="amount">{formik.touched.amount && formik.errors.amount ? formik.errors.amount : 'Coupon Amount'}</label>
                                <input className={classes.formInputs} type='number' name="amount" placeholder="Enter coupon amount" value={formik.values.amount} onChange={formik.handleChange} />
                            </div>
                            <div className={classes.formGroup}>
                                <label className={`${classes.formLabels} ${formik.touched.peruseamount && formik.errors.peruseamount && classes.labelError}`} htmlFor="peruseamount">{formik.touched.peruseamount && formik.errors.peruseamount ? formik.errors.peruseamount : 'Per Use Amount'}</label>
                                <input className={classes.formInputs} type='number' name="peruseamount" placeholder="Enter Per Use amount" value={formik.values.peruseamount} onChange={formik.handleChange} />
                            </div>

                            <div className={classes.formGroup}>
                                <label
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    className={`${classes.formLabels}  ${formik.touched.isonetimeuse && formik.errors.isonetimeuse ? classes.labelError : ''}`}
                                    htmlFor="isonetimeuse">
                                    {formik.touched.isonetimeuse && formik.errors.isonetimeuse ? formik.errors.isonetimeuse : 'One Time Use'}

                                    <label className={classes.switch}>
                                        <input
                                            className={classes.checkbox}
                                            name="isonetimeuse"
                                            type="checkbox"
                                            checked={formik.values.isonetimeuse}
                                            onChange={formik.handleChange}
                                        // Remove the disabled attribute unless it's necessary
                                        />
                                        <span className={`${classes.slider} ${classes.round}`}></span>
                                    </label>
                                </label>
                            </div>


                            <button className={classes.submitBtn} disabled={loading} type="submit">{loading ? <CircularProgress size={30} className={classes.btnLoading} /> : "Create"}</button>
                        </form>

                        {apiSuccess.hasOwnProperty('data') &&
                            <div className={classes.couponSuccess}>
                                <h6>Coupon Code: {apiSuccess.data.coupon_code}</h6>
                                <h6>Coupon Amount: {apiSuccess.data.coupon_total_amount}</h6>
                                <h6>Coupon Per Use Amount: {apiSuccess.data.per_use_amount}</h6>
                                <h6>One Time Use: {apiSuccess.data.one_time_use ? "Yes" : "No"}</h6>

                            </div>
                        }
                    </div>
                </div>
            </div>
            <ToastContainer theme='colored' />
        </>
    )

}

export default withStyles(styles)(GlobalCoupon)