import axios from 'axios';

/**
 * @description Get API to fetch ALL Payments List
 * @async
 * @function GetPaymentAPI
 * @returns Promise
**/


const GetPaymentAPI = async (page, size) => {

  try {

    let apiUrl = process.env.REACT_APP_API_URL + "/payment-history?pageNumber=" + page + "&pageSize=" + size;
    const config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access-token')
      }
    }

    const response = await axios.get(apiUrl, config)
  
    return response.data

  } catch (e) {
    return e
  }
}

export default GetPaymentAPI;
