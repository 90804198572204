import axios from 'axios';

/**
 * @description POST API to create coupon
 * @async
 * @function PostCreateCouponAPI
 * @returns Promise
**/


const PostCreateCouponAPI = async (businessEmail, couponAmount) => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/coupon/create`;
    const config = {
      headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access-token')
      } 
    }
    const formBody = {
      "business_email": businessEmail,
      "coupon_amount": couponAmount
    }

    try {
      const response = await axios.post(apiUrl, formBody, config)
      return response.data
  
    } catch (e) {
      return e
    }
}

export default PostCreateCouponAPI;
  